<template>
    <section class="cont">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>日志管理</el-breadcrumb-item>
             <el-breadcrumb-item>登录日志</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <el-row class="content-box">
          <!-- 查询框 -->
          <el-row class="search-box">
             <el-row class="search-row">
                <el-row class="search-item">
                   <label>用户名：</label>
                   <el-input class="width-220"  v-model="param.userNick" :placeholder="$t('msg.user_name_nickname')" clearable></el-input>
                </el-row>
                <el-row class="search-item">
                   <label>登录时间：</label>
                   <date-packer class="width-220" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
                </el-row>
                <el-row class="search-item">
                   <label>状态：</label>
                   <el-button-group class="m-right-10">
                      <el-button :class="param.state === 'ENABLED' ? 'bg-gradient' : ''" @click="queryState('ENABLED')" ><span v-text="$t('msg.success')">成功</span></el-button>
                      <el-button :class="param.state === 'DISABLED' ? 'bg-gradient' : ''" @click="queryState('DISABLED')" ><span v-text="$t('msg.fail')">失败</span></el-button>
                   </el-button-group>
                </el-row>
                <el-button class="bg-gradient" type="primary" icon="el-icon-search"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                <el-button type="primary" icon="el-icon-refresh-right" plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
             </el-row>
          </el-row>
          <!-- 主体内容 -->
          <el-row class="table-box">
            <!-- 表格 -->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border fit
                    style="width: 100%"
                    :stripe="true">
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
                <el-table-column :label="$t('msg.head_portrait')" min-width="50"></el-table-column>
                <el-table-column prop="userName" :label="$t('msg.user_name')" min-width="70">
                    <template>
                        <!--<span><i class="icon iconfont" style="color: #1ABC9C;font-size: 1.4rem;margin-right: 4px" v-html="scope.row.icon"></i>{{scope.row.name}}</span>-->
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" :label="$t('msg.nickname')" min-width="140"></el-table-column>
                <el-table-column prop="system" :label="$t('msg.operating_system')"></el-table-column>
                <el-table-column prop="client" :label="$t('msg.terminal')"></el-table-column>
                <el-table-column prop="ipAddress" :label="$t('msg.ip_address')" min-width="70"></el-table-column>
                <el-table-column :label="$t('msg.status')" min-width="50">
                    <template slot-scope="scope">
                        <span style="font-size: 1.4rem;color: #1ABC9C" class="icon iconfont" v-html="scope.row.state==='ENABLED'?'&#xe7f0;':''"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="loginTime" :label="$t('msg.login_time')" min-width="90"></el-table-column>
                <el-table-column prop="logoutTime" :label="$t('msg.exit_time')" min-width="90"></el-table-column>
                <el-table-column prop="remark" :label="$t('msg.remark')" min-width="150"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-row>
       </el-row>
    </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   export default {
      data(){
         return{
            tableData: [],      // 表格数据
            loading: true,      // 加载动画
            dateValue: [],      // 登录时间段
            total: 0,           // 总数目数
            param: {
               userNick: '',
               state: '',
               beginLoginTime: '',
               endLoginTime: '',
               page: 1,
               limit: 0
            }
         }
      },
      mounted() {
         this.param.limit = sessionStorage.getItem('pageSize') * 1
         this.getLogs()
      },
      methods: {
         // 获取登录日志列表
         getLogs(){
            const param = { ...this.param }
            const url = system.loginLogPaging
            this.$axios.post(url, param).then((res) => {
               if(res.searchCount){
                  this.total = res.total
                  this.loading = false
                  this.tableData = res.records
               }
            })
         },
         // 获取登录状态
         queryState(val){
            this.param.state = val
            this.getLogs()
         },
         // 获取登录时间段
         getDatePacker(val){
            this.dateValue = val
            this.param.beginLoginTime = val[0]
            this.param.endLoginTime = val[1]
         },
         // 搜索登录日志
         handleQuery(bool){
            this.param.page = 1
            if (bool) return this.getLogs()
            this.param.userNick = ''
            this.param.state = ''
            this.getDatePacker([])
         },
         // 改变每页数
         pageChange(num){
            this.param.limit = num
            this.getLogs()
         },
         // 改变当前页
         handlePaging(num){
            this.param.page = num
            this.getLogs()
         },
      }
   }
</script>

<style scoped></style>
